import { version } from '$app/environment';
import { registerHandlers } from '$lib/api/registration';
import * as Sentry from '@sentry/sveltekit';

registerHandlers();

if (import.meta.env.MODE !== 'development') {
	Sentry.init({
		dsn: 'https://365226b6423949bbd5188dd1e6e3ba2c@o4508421939986432.ingest.de.sentry.io/4508438618898512',
		tracesSampleRate: 1.0,
		release: version,
		environment: import.meta.env.MODE,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [Sentry.replayIntegration()]
	});
}

// // If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
